import React, { Component } from 'react'
import { db } from '../firebase'
import { withRouter } from 'react-router-dom'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Helmet} from "react-helmet";
import { PlyrComponent } from 'plyr-react';
import './Privacy.css'

class Privacy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      voiceMsgData: {}
    }
  }

  componentDidMount() {
    console.log(this.props.match);
  }

  render() {
    return (
      <div class="voicemsgContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>VoiceMsg</title>
          <link rel="alternate" type="application/json+oembed"
  href="http://www.youtube.com/oembed?url=https%3A%2F%2Fyoutu.be%2Fjw7bRnFbwAI&format=json"
  title="Inclusive Components with Heydon Pickering" />

          <meta property="og:title" content="VoiceMsg" />
          <meta property="og:site_name" content="VoiceMsg" />
          <meta property="og:description" content="Send Voice Messages for Free" />
          <meta property="og:image:width" content="500" />
          <meta property="og:image:height" content="500" />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:type" content="video.other" />
          <meta property="og:image" content="https://picsum.photos/200/300" />
          <meta property="twitter:title" content="VoiceMsg" />
          <meta property="twitter:card" content="player" />
          <meta property="twitter:description" content="Send Voice Messages for Free" />
          <meta property="twitter:player" content="https://www.loom.com/embed/0ebb5df51a984cb39ff77b3fe9f7dbae" />

        </Helmet>

        <div id="hosted" style="max-width: 838px; height:100%; margin: 0 auto;">
          <div style="margin: 0px auto; font-family: Roboto, Arial; padding: 50px 10px 100px; user-select: none;">
              <div data-custom-class="body">
                  <p style="font-size:15px;"><strong><span style="font-size: 26px;"><span data-custom-class="title"><bdt class="block-component"></bdt>PRIVACY NOTICE<bdt class="else-block"></bdt></span></span></strong></p>
                  <p style="font-size:15px;"><span style="color: rgb(127, 127, 127);"><strong><span data-custom-class="subtitle">Last updated <bdt class="question">January 21, 2020</bdt></span></strong>
                      </span>
                  </p>
                  <p style="font-size: 15px; line-height: 1.5;">
                      <br />
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Thank you for choosing to be part of our community at <bdt class="question">Yap Inc.</bdt><bdt class="block-component"></bdt> (“<bdt class="block-component"></bdt><strong>Company</strong><bdt class="statement-end-if-in-editor"></bdt>”, “<strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>notice<bdt class="else-block"></bdt></span></span>, or our practices with regards to your personal information, please contact us at
                      <bdt class="question">support@getyap.app</bdt>.</span>
                      </span>
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">When you visit our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="forloop-component"></bdt><bdt class="block-component"></bdt> mobile application,<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="forloop-component"></bdt><bdt class="statement-end-if-in-editor"></bdt> and use our services, you trust us with your personal information. We take your privacy very seriously. In this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span></span>, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>that you do not agree with, please discontinue use of our
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component"></bdt>Apps
                      <bdt class="statement-end-if-in-editor"></bdt> and our services.</span>
                      </span>
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">This <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>applies to all information collected through our
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component"></bdt>
                      <bdt class="forloop-component"></bdt>
                      <bdt class="block-component"></bdt> mobile application,
                      <bdt class="statement-end-if-in-editor"></bdt>
                      <bdt class="block-component"></bdt>
                      <bdt class="forloop-component"></bdt>&nbsp;</span><span data-custom-class="body_text">("<strong>Apps</strong>"),<bdt class="statement-end-if-in-editor"></bdt> and/or any related services, sales, marketing or events (we refer to them collectively in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>as the "<strong>Services</strong>").</span>
                      </span>
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Please read this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>carefully as it will help you make informed decisions about sharing your personal information with us.</span>
                      </strong>
                      </span>
                  </p>
                  <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                  <p style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">TABLE OF CONTENTS</span></span>
                      </strong>
                      </span>
                  </p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#infocollect"><span style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE COLLECT?</span></a> <span style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#infouse"><span style="color: rgb(89, 89, 89);">2. HOW DO WE USE YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                  </p>
                  <p style="font-size:15px;"><span style="color: rgb(89, 89, 89);"><a data-custom-class="link" href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a><bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                      </span><span style="font-size: 15px; color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span>
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component"></bdt>
                  </p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#inforetain"><span style="color: rgb(89, 89, 89);">4. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></span>
                  </p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#infosafe"><span style="color: rgb(89, 89, 89);">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                      <bdt class="block-component"></bdt>
                      </span>
                  </p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#infominors"><span style="color: rgb(89, 89, 89);">6. DO WE COLLECT INFORMATION FROM MINORS?</span></a>
                      <bdt class="statement-end-if-in-editor"></bdt>
                  </p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#privacyrights"><span style="color: rgb(89, 89, 89);">7. WHAT ARE YOUR PRIVACY RIGHTS?<bdt class="block-component"></bdt></span></a></p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#DNT"><span style="color: rgb(89, 89, 89);">8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#caresidents"><span style="color: rgb(89, 89, 89);">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#policyupdates"><span style="color: rgb(89, 89, 89);">10. DO WE MAKE UPDATES TO THIS POLICY?</span></a></p>
                  <p style="font-size:15px;"><a data-custom-class="link" href="#contact"><span style="color: rgb(89, 89, 89);">11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></a></p>
                  <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                  <p id="infocollect" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></span>
                      </strong><span style="font-size: 19px;"><span data-custom-class="heading_1"><span style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></span>
                      </span>
                      </span><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                  </p>
                  <p style="font-size: 15px;"><strong><span data-custom-class="heading_2"><br /><span style="font-size: 16px;">Information automatically collected</span></span></strong></p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:&nbsp;</span></span>&nbsp;</em>&nbsp;</strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>.</span></span></em></span>
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt> and other technical information. This information is primarily needed to maintain the security and operation of our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>, and for our internal analytics and reporting purposes.<bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span>
                      </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span>
                  </p>
                  <p style="font-size: 15px;"><strong><span style="font-size: 16px;"><span data-custom-class="heading_2"><strong><span data-custom-class="heading_2"><br />Information collected through our Apps</span></strong></span>
                      </span>
                      </strong>
                  </p>
                  <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em> &nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We may collect information regarding your<bdt class="block-component"><bdt class="block-component"></bdt></bdt> mobile device,<span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><em><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt class="statement-end-if-in-editor"></bdt></bdt></span></em>
                      </span>
                      </span>
                      <bdt class="block-component"></bdt> push notifications,
                      <bdt class="statement-end-if-in-editor"></bdt>
                      <bdt class="forloop-component"></bdt><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><em><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></em>
                      </span>
                      </span>
                      <bdt class="forloop-component"></bdt> <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><em><span data-custom-class="body_text">when you use our apps.</span></em>
                      </span>
                      </span>
                      </span>
                      </em>
                      </span>
                      </span>
                  </p>

                  <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">If you use our Apps, we may also collect the following information:<bdt class="block-component"></bdt></span></span>
                      <bdt class="block-component">
                          <bdt class="block-component"></bdt>
                      </bdt>
                      <br />
                      <br />
                      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Mobile Device Data.</em> We may automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span>
                          </span>
                          </span>
                          </span>
                          </span>
                          <br />
                          <br />
                      </li>
                      <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Push Notifications.</em> We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.<span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                          </span>
                          <bdt class="forloop-component"></bdt><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                          </span>
                          <bdt class="forloop-component"></bdt><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                          </span>
                          </span>
                          </span>
                          </span>
                      </li>
                      <p style="font-size: 15px;"><strong><span style="font-size: 16px;"><span data-custom-class="heading_2"><strong><span data-custom-class="heading_2"><br />Extra information regarding Yap's use of Apple's Truedepth API</span></strong></span>
                          </span>
                          </strong>
                      </p>
                      <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text">
                        Yap offers a camera effect similar to Apple Animoji that uses the device's TrueDepth camera system to track the movement of your eyes and mouth to offer a realistic effect. In order for this functionality to be possible, the app requires access to your device's camera. This access can be toggled on or off at any time in your device's settings. The camera images and resulting depth data are only used for the camera effect. The video feed is never used, and its data is never stored locally or remotely. Camera access is required to get depth data from Apple's API's. This data is stored on the device during the app session. Every time the home screen is presented or the app is closed, the data is deleted. This data is used to make your camera experience more enjoyable. Face data is never stored remotely, given to third parties, or used for any other purposes. The face data only remains on your device during app session, and never leaves your device at any time. It's never shared with 3rd parties. Face data is never stored remotely, given to third parties, or used for any other purposes. The face data only remains on your device during app session, and never leaves your device at any time.

                          <bdt class="block-component"></bdt><bdt class="block-component"></bdt>
                          </span>
                          </span>
                          </span>
                        </p>


                      <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span>
                          </span>
                          </span>
                      </p>
                      <p style="font-size: 15px; line-height: 1.5;">
                          <br />
                      </p>
                      <p id="infouse" style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">2. HOW DO WE USE YOUR INFORMATION?</span></span>
                          </strong>
                          </span>
                      </p>
                      <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span></em>
                          </span>
                          </span>
                      </p>
                      <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">We use personal information collected via our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                          </span>
                      </p>
                      <p style="font-size:15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">We use the information we collect or receive:<bdt class="block-component"></bdt></span></span>
                          </span>
                      </p>
                      <ul>
                          <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">To facilitate account creation and logon process.</span></strong><span data-custom-class="body_text">&nbsp;If you choose to link your account with us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. <bdt class="block-component"></bdt></span></span>
                              </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                              <br />
                              <br />
                          </li>
                          <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">To enable user-to-user communications.</span></strong><span data-custom-class="body_text">&nbsp;We may use your information in order to enable user-to-user communications with each user's consent.</span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              <bdt class="block-component"></bdt><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                          </li>
                      </ul>
                      <p style="font-size: 15px; line-height: 1.5;">
                          <br />
                      </p>
                      <p id="infoshare" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></span>
                          </strong>
                          </span>
                      </p>
                      <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</span></em>
                          </span>
                          </span>
                      </p>
                      <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We may process or share data based on the following legal basis:</span></span>
                      </div>
                      <ul>
                          <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose.</span>
                              <br />
                              <br />
                              </span>
                          </li>
                          <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</span>
                              <br />
                              <br />
                              </span>
                          </li>
                          <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Performance of a Contract:&nbsp;</strong>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</span>
                              <br />
                              <br />
                              </span>
                          </li>
                          <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</span>
                              <br />
                              <br />
                              </span>
                          </li>
                          <li><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></span>
                          </li>
                      </ul>
                      <p style="font-size:15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                          </span>
                      </p>
                      <ul>
                          <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Vendors, Consultants and Other Third-Party Service Providers.</span></strong><span data-custom-class="body_text">&nbsp;We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>, which will enable them to collect data about how you interact with the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt> over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. <bdt class="block-component"></bdt></span></span>
                              </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span>
                              <br />
                              <br />
                          </li>
                          <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Business Transfers.</span></strong><span data-custom-class="body_text">&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.<bdt class="block-component"></bdt></span></span>
                              </span>
                              <br />
                              <br />
                          </li>
                          <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Third-Party Advertisers.</span></strong><span data-custom-class="body_text">&nbsp;We may use third-party advertising companies to serve ads when you visit the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you. <bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                              <bdt class="block-component"></bdt><span data-custom-class="body_text"><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span><span style="color: rgb(89, 89, 89);"><bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                              <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                          </li>
                      </ul>
                      <div>
                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt><span style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt class="block-component"><span data-custom-class="heading_1"></span></bdt>
                          </span>
                          </span>
                          </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span>
                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                          <bdt class="block-component"></bdt>
                          </span>
                          </span>
                          </span>
                          </span>
                          <p></p>
                          <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                          <p id="inforetain" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">4. HOW LONG DO WE KEEP YOUR INFORMATION?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We keep your information for as long as necessary to fulfill the purposes outlined in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>unless otherwise required by law.</span>
                              </em>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span></span>, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than
                              <bdt class="block-component"></bdt>
                              <bdt class="question">__________</bdt>
                              <bdt class="else-block"></bdt>.</span>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></span><span style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></p>
                          <div style="line-height: 1.5;">
                              <br />
                          </div>
                          <p id="infosafe" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We aim to protect your personal information through a system of organizational and technical security measures.</span></em>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt> is at your own risk. You should only access the services within a secure environment.</span></span><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                          <p id="infominors" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">6. DO WE COLLECT INFORMATION FROM MINORS?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We do not knowingly collect data from or market to children under 18 years of age.</span></em>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at <bdt class="block-component"></bdt><bdt class="question">__________</bdt><bdt class="statement-end-if-in-editor"></bdt>.</span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                          <p id="privacyrights" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">7. WHAT ARE YOUR PRIVACY RIGHTS?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><span data-custom-class="body_text"><em><bdt class="block-component"></bdt>You may review, change, or terminate your account at any time.</em></span>
                              <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:</span></span>&nbsp;</span><span data-custom-class="body_text"><span style="color: rgb(48, 48, 241);"><span style="font-size: 15px;"><a data-custom-class="link" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</span></span>
                              </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                              <bdt class="block-component"></bdt>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you have questions or comments about your privacy rights, you may email us at <bdt class="question">support@getyap.app</bdt>.</span>
                              <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                              </span>
                          </p>
                          <div style="line-height: 1.1;">
                              <br />
                          </div>
                          <div><span style="font-size: 16px;"><span style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_2">Account Information</span></strong>
                              </span>
                              </span>
                          </div>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:<bdt class="forloop-component"></bdt></span></span>
                              </span>
                          </p>
                          <p style="font-size: 15px;">&nbsp; &nbsp; ■ &nbsp;<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="question">support@getyap.app</bdt> <bdt class="forloop-component"></bdt></span></span>
                              </span>
                          </p>
                          <p style="font-size: 15px;">&nbsp; &nbsp; ■ &nbsp;<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="question">Log into your account settings and update your user account.</bdt> <bdt class="forloop-component"></bdt></span></span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><strong><u><span data-custom-class="body_text">Opting out of email marketing:</span></u>&nbsp;</strong><span data-custom-class="body_text">You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:<bdt class="forloop-component"></bdt><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style="font-size: 15px;"><span style="font-size: 15px;"><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;">
                              <br />
                          </p>
                          <p id="DNT" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span></span>.</span>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;">
                              <br />
                          </p>
                          <p id="caresidents" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span></em>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>, you have the right to request removal of unwanted data that you publicly post on the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>Apps<bdt class="statement-end-if-in-editor"></bdt>, but please be aware that the data may not be completely or comprehensively removed from our systems.</span></span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                          <p id="policyupdates" style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">10. DO WE MAKE UPDATES TO THIS POLICY?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span style="font-size: 15px;"><span data-custom-class="body_text">In Short:</span></span>&nbsp;</em>&nbsp;</strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, we will update this policy as necessary to stay compliant with relevant laws.</span></em>
                              </span>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We may update this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span></span>, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"></bdt>privacy notice<bdt class="else-block"></bdt></span>&nbsp;</span>frequently to be informed of how we are protecting your information.</span>
                              </span>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br /></span></p>
                          <p id="contact" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></span>
                              </strong>
                              </span>
                          </p>
                          <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">If you have questions or comments about this policy, you may <bdt class="block-component"></bdt>email us at <bdt class="question">support@getyap.app</bdt><bdt class="statement-end-if-in-editor"></bdt> or by post to:</span></span>
                          </p>
                          <div><span style="color: rgb(89, 89, 89);"><bdt class="question"><span style="font-size: 15px;"><span data-custom-class="body_text">Yap Inc.</span></span>&nbsp;</bdt><span data-custom-class="body_text"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                              </span>
                              </span>
                          </div>
                          <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="question">101 Main St.</bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                          </div>
                          <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="question">Oakland</bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt>, <bdt class="question">CA</bdt><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt> <bdt class="question">94611</bdt><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span>
                              </span>
                          </div>
                          <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="question">United States</bdt><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span>
                              </span>
                              </span>
                              </span>
                              <bdt class="else-block"></bdt>
                              </span>
                              </span>
                              </span><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt class="statement-end-if-in-editor"></bdt><bdt class="statement-end-if-in-editor"></bdt></span></span>
                              </span><span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>
                              </span>
                          </div>
                          <div>
                              <br />
                          </div>
                          <div>
                              <bdt class="block-component"></bdt>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

        }
      </div>
    )
  }
}

export default withRouter(Privacy)
