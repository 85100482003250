import React, { Component } from 'react'
import logo from './logo.svg';
import './App.css';
import { db } from './firebase'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import VoiceMsg from './VoiceMsg/VoiceMsg'
import Privacy from './Privacy/Privacy'
import Home from './Home/Home'

class Root extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <Home {...props} />}
          />
          <Route
            exact
            path="/*"
            render={props => <VoiceMsg {...props} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default Root
