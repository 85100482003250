import React, { Component } from 'react'
import { db } from '../firebase'
import { withRouter } from 'react-router-dom'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Helmet} from "react-helmet";
import { PlyrComponent } from 'plyr-react';
import './Home.css'
import images from '../images'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      voiceMsgData: {}
    }
  }

  componentDidMount() {
    console.log(this.props.match);
  }

  render() {
    return (
      <div class="homeBodyContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cleartone</title>
          <meta property="og:title" content="Cleartone" />
          <meta property="og:site_name" content="Cleartone" />
          <meta property="og:description" content="Voice messaging for email, Slack and more." />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />
          <meta property="og:image" content="https://cleartone.app/apple-touch-icon.png" />
          <meta property="twitter:title" content="Cleartone" />
          <meta property="twitter:card" content="player" />
          <meta property="twitter:description" content="Voice messaging for email, Slack and more." />
          <meta property="twitter:image" content="https://cleartone.app/apple-touch-icon.png" />

        </Helmet>

        <div class="header">
          <center>
            <img src={images.logoInline} width="186" />
          </center>
        </div>

        <div class="homeContainer">
          <span class="tagline">Say it, don't type it</span>
          <h4>Save time communicating effectively with <b>voice messaging.</b></h4>


          <img src={images.howItWorks} class="howItWorks" />

          <a href="https://apps.apple.com/us/app/cleartone/id1508857636?ls=1" target="_blank" class="btnPrimary">Get the Free Mac App</a>
        </div>

        <div class="footer">
          <center>


          </center>
        </div>

      </div>
    )
  }
}

export default withRouter(Home)
