import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyBBki7Nst06AuB_iqwL9zN8gJz4qyPPioQ",
  authDomain: "voicemsg-2255f.firebaseapp.com",
  databaseURL: "https://voicemsg-2255f.firebaseio.com",
  projectId: "voicemsg-2255f",
  storageBucket: "voicemsg-2255f.appspot.com",
  messagingSenderId: "495721626188",
  appId: "1:495721626188:web:7346bf70a3097925e392e9"
}

firebase.initializeApp(config)
// firebase.firestore().settings({
//   timestampsInSnapshots: true
// })

export const db = firebase.firestore()
