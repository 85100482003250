import React, { Component } from 'react'
import { db } from '../firebase'
import { withRouter } from 'react-router-dom'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Helmet} from "react-helmet";
import { PlyrComponent } from 'plyr-react';
import './VoiceMsg.css'
import images from '../images'

class VoiceMsg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      voiceMsgData: {}
    }
  }

  componentDidMount() {
    console.log(this.props.match);
    this.fetchData(this.props.match.params[0])
  }

  fetchData = (id) => {
    db.collection("voiceMsgs")
      .doc(id)
      .onSnapshot(snapshot => {
          var data = snapshot.data();
          this.setState({voiceMsgData: data});
          console.log(data);
        },
        err => {
          console.log(err.toString())
        }
      )
  }

  render() {
    // <meta property="og:image:width" content="500" />
    // <meta property="og:image:height" content="500" />
    // <meta property="og:video:type" content="video/mp4" />
    // <meta property="og:type" content="video.other" />
    // <meta property="og:image" content="https://picsum.photos/200/300" />
    return (
      <div class="voicemsgBodyContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cleartone</title>
          <meta property="og:title" content="Cleartone" />
          <meta property="og:site_name" content="Cleartone" />
          <meta property="og:description" content="Voice messaging for email, Slack and more." />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />
          <meta property="og:image" content="https://cleartone.app/apple-touch-icon.png" />
          <meta property="twitter:title" content="Cleartone" />
          <meta property="twitter:card" content="player" />
          <meta property="twitter:description" content="Voice messaging for email, Slack and more." />
          <meta property="twitter:image" content="https://cleartone.app/apple-touch-icon.png" />

        </Helmet>

        <div class="header">
          <center>
            <a href="/"><img src={images.logoInline} width="186" /></a>
            <h4>Voice messaging for email, Slack and more.</h4>
          </center>
        </div>

        <div class="voicemsgContainer">
          {this.state.voiceMsgData.url &&
            <PlyrComponent sources={{
                type: 'audio',
                sources: [
                {
                    src: this.state.voiceMsgData.url,
                    type: 'audio/mp3'
                }
            ]}} options={{controls: ['play', 'progress', 'settings'], autoplay: true, resetOnEnd: true}} />
          }
        </div>

        <div class="footer">
          <center>
            <h2>Save time. Communicate effectively.</h2>
            <a href="https://apps.apple.com/us/app/cleartone/id1508857636?ls=1" target="_blank" class="btnPrimary">Get the Free Mac App</a>
          </center>
        </div>

      </div>
    )
  }
}

export default withRouter(VoiceMsg)
